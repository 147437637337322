<div class="hbox">
  <div class="flex-box">
    <loading class="regLoading" *ngIf="(!regimen?.class || loading) && !loadingByChild"></loading>
    <div class="flex-content tw-flex tw-items-center tw-justify-center tw-fixed tw-left-0 tw-right-0 tw-top-0 tw-bottom-0" *ngIf="loadingByChild" style="background: rgba(0, 0, 0, 0.5); z-index: 11">
      <loading></loading>
    </div>
    <div class="flex-content pos-rel tw-relative" #regimenModuleContainer="">
      <div *ngIf="regimen.regimenPreparation" class="tw-fixed tw-left-6 tw-top-[42px] tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10 tw-z-20" (click)="back()" [ngClass]='{"tw-absolute": regimen && regimen.showProductSummary && regimenModuleContainer && regimen}'>
        <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
      </div>
      <ng-container *ngIf="chatLanguageChangeEnabled && consultationSession; then languageChangeHeader else defaultHeader"></ng-container>
      <ng-template #defaultHeader>
      <!-- default header -->
      <div *ngIf="!regimen.regimenPreparation" class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-relative tw-z-[100]">
        <div class="tw-fixed tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10 tw-z-20" (click)="back()" [ngClass]='{"tw-absolute": regimen && regimen.showProductSummary && regimenModuleContainer && regimen}'>
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
        </div>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@getYourTreatment" *ngIf="!regimen.showProductSummary && !loading">Get your treatment kit</span>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@instructions" *ngIf="regimen.showProductSummary && !loading">Instructions</span>
      </div>
      </ng-template>
      <ng-template #languageChangeHeader>
      <!-- language change header -->
      <div *ngIf="!regimen.regimenPreparation" class="tw-sticky tw-top-0 tw-flex tw-justify-between tw-items-center tw-h-14 tw-pl-2 tw-pr-4 tw-bg-black tw-z-[100]">
        <div class="tw-flex tw-items-center">
          <div class="tw-flex tw-justify-center tw-items-center tw-p-2">
            <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'" (click)="back()"></cs-icon>
          </div>
          <span *ngIf="regimen.class === 'FACE'" class="tw-font-body tw-font-normal tw-text-gray-50" [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta', 'tw-text-300': user?.get('languagePreference') !== 'ta'}" i18n="@@discoverClearSkin">Discover clear skin</span>
          <span *ngIf="regimen.class === 'HAIR'" class="tw-font-body tw-font-normal tw-text-gray-50" [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta', 'tw-text-300': user?.get('languagePreference') !== 'ta'}" i18n="@@discoverHealthyHair">Discover healthy hair</span>
        </div>
        <div class="tw-flex tw-items-center">
          <div class="tw-flex tw-justify-center tw-items-center tw-gap-1 tw-shrink-0 tw-h-6 tw-px-2 tw-bg-transparent tw-border tw-border-solid tw-border-teal-100 tw-rounded-6" (click)="openLanguageChangeDrawer()">
            <span class="tw-font-body tw-text-100 tw-font-bold tw-text-gray-50">{{appConfig.Shared.LanguagesLong[user.get('languagePreference')?.toUpperCase() || appConfig.Shared.Languages.EN.toUpperCase()]}}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
              <mask id="mask0_24481_23258" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="9" height="9">
                <rect x="8.5" y="0.0175781" width="8" height="8" transform="rotate(90 8.5 0.0175781)" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_24481_23258)">
                <path d="M1.16634 2.69274L1.75801 2.10107L4.49967 4.84274L7.24134 2.10107L7.83301 2.69274L4.49967 6.02607L1.16634 2.69274Z" fill="white"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
      </ng-template>
      <toolbar-tabs class="tw-sticky tw-top-0 tw-z-10" #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [hidden]="redirectAfterRegimenPreparation">
        <tab1 class="tw-uppercase" i18n="@@face">Face</tab1>
        <tab2 class="tw-uppercase" i18n="@@hair">Hair</tab2>
        <tab3>BODY</tab3>
      </toolbar-tabs>
      <!--chat progress bar-->
      <div id="chatProgressBar" #chatProgressBar class="tw-bg-yellow-100 tw-sticky tw-top-12 tw-w-full tw-left-0 tw-z-20" *ngIf="consultationSession && chatProgressExperiment && chatProgressType === 'ConsultationSession' && !isChatLoading">
        <div class="tw-h-2 tw-bg-yellow-500 tw-transition-all tw-duration-500 tw-ease-in-out" [style]="{ 'width': chatProgressValue + '%' }"></div>
      </div>
      <ng-container *ngIf="showReAcquisitionBanner">
        <div class="card tw-flex tw-flex-row tw-items-center tw-gap-4 tw-rounded-2xl tw-px-5 tw-py-4 tw-mx-4 tw-bg-orange-100 tw-mt-3 tw-mb-3 tw-font-bold" (click)="handleClick()">
          <img class="tw-flex-none tw-h-20 tw-w-20 tw-rounded-full bg-black tw-bg-green-300" src="https://storage.googleapis.com/heallo-other-user-files/doctor_api_mahima_aggarwal_12052022_1670250785248.jpg" />
          <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-2">
            <div>
              <p class="tw-font-body tw-my-2 tw-text-400 tw-text-gray-700" i18n="@@wantDoctorCheckup"> Do you want to take a doctor checkup? </p>
            </div>
            <cs-button [class]="'tw-tracking-wide primary btn-xxxs btn-xxxs-padding tw-px-2 btn-float '">
              <cs-button-text class="tw-font-body" i18n="@@takeCheckupNow">Take Checkup Now</cs-button-text>
            </cs-button>
          </div>
        </div>
      </ng-container>
      <user-view-regimen-empty *ngIf="!loading && regimen.class && !regimen.objectId && !consultationSession" [regimen]="regimen" (onTriggerTree)="triggerTreeForRegimen()"></user-view-regimen-empty>
      <regimen-instruction class="w100" *ngIf="regimen && regimen.showProductSummary" [regimen]="regimen" [supportTicketId]="supportTicketId" [experiments]="experiments" [regimenContentElement]="regimenModuleContainer" [newInstructionUIExperiment]="newInstructionUIExperiment" [scrollingDown]="scrollingDown"></regimen-instruction>
      <user-view-regimen-unpaid class="w100" *ngIf="regimen && regimen.showPriceDetail" [ngClass]="{'tw-pb-[94px]': true}" [experiments]="experiments" [regimen]="regimen" [fromPreparation]="redirectAfterRegimenPreparation" (showSummaryView)="showSummaryView()" (buyNow)="buyRegimen()" (cartChange)="updatePrice($event)" (updateRegimen)="updateRegimen($event)" (parentLoading)="parentLoading($event)" (selectedPlanEmit)="selectedPlan($event)" [isRegimenNewPropositionUI]="isRegimenNewPropositionUI"></user-view-regimen-unpaid>
      <user-view-regimen-consultation class="w100" *ngIf="regimen && regimen.showConsultationView" [regimen]="regimen" [socialProof]="socialProof" [experiments]="experiments"></user-view-regimen-consultation>
      <user-view-regimen-preparation class="w100" *ngIf="(regimen && regimen.regimenPreparation)" [regimen]="regimen" [experiments]="experiments" [renderReport]="showSkinReport" (showRegimen)="resetFlagAndShowRegimen()" (hideToolBars)="redirectAfterRegimenPreparation = true"></user-view-regimen-preparation>
      <chatV2-view *ngIf="consultationSession" [hideNavBar]="true" [chatScrollErrorY]="headerHeight" (chatScrollPossible)="autoScrollToBottom()" title="Consultation" [chatUniqueObject]="consultationSession" [where]="{ user, consultationSession }" (chatLoadingStatus)="handleLoadingStatus($event)" dataFunctionName="findConsultationSessionChat"></chatV2-view>
    </div>
    <div class="flex-footer" *ngIf="(!loading && regimen && regimen.showPriceDetail && regimen.totalSP && !regimen.regimenPreparation && !regimen.active && !regimen.orderPlaced) || (regimen.showConsultationView && !regimen.orderPlaced)" style="padding: 20px 0; background: white !important">
      <button class="buy-now-btn shine" id="buyNowButton" (click)="buyRegimen()" [disabled]="isInternalUser">
        <div class="svg-container">
          <svg class="anim-image orange-spin" xmlns="http://www.w3.org/2000/svg" width="64.443" height="67.447" viewBox="0 0 64.443 67.447" [ngStyle]="{'fill': discountSpinnerFillColor}">
            <path id="Path_23051" data-name="Path 23051" d="M87.025,208.059,79.5,203.146l-2.347-8.676a3.039,3.039,0,0,0-3.088-2.243l-8.976.449-7-5.639a3.042,3.042,0,0,0-3.817,0l-7,5.639-8.975-.449a3.031,3.031,0,0,0-3.088,2.243l-2.347,8.676-7.525,4.912a3.042,3.042,0,0,0-1.179,3.63l3.2,8.4-3.2,8.4a3.042,3.042,0,0,0,1.179,3.63l7.525,4.913,2.347,8.675a3.034,3.034,0,0,0,3.088,2.243l8.975-.449,7,5.639a3.041,3.041,0,0,0,3.817,0l7-5.639,8.976.449a3.036,3.036,0,0,0,3.088-2.243l2.347-8.675,7.525-4.913a3.042,3.042,0,0,0,1.179-3.63l-3.2-8.4,3.2-8.4A3.042,3.042,0,0,0,87.025,208.059Z" transform="translate(-23.961 -186.363)"></path>
          </svg>
          <span class="off">{{regimen.discount}}% <br />off </span>
        </div>
        <span class="btn-txt">
          <span [ngStyle]="{ 'font-size': user.get('languagePreference') === 'ta' || user.get('languagePreference') === 'ml' ? '12px' : '18px' }" i18n="@@buyNow">Buy Now</span>
          <span> &#64;</span>
          <span class="original">₹{{regimen.totalMRP}}</span>
          <span>₹{{regimen.totalSP}}</span>
        </span>
      </button>
    </div>
    <div class="flex-footer shadow" *ngIf="regimen.class && !loading">
      <div class="w100" *ngIf="offerTimer.endTime > 0 && (!regimen.active && !regimen.orderPlaced)">
        <discount-timer [endTime]="offerTimer.endTime" [text]="offerTimer.title"></discount-timer>
      </div>
      <div class="w100 p15" *ngIf="!regimen.objectId && !consultationSession && !regimen.regimenPreparation && !newInstructionUIExperiment">
        <cs-button [class]="'w100 primary'" [disabled]="isInternalUser" (callback)="triggerTreeForRegimen()">
          <cs-button-text class="tw-font-body" i18n="@@getYourTreatment">Get your treatment kit</cs-button-text>
        </cs-button>
      </div>
      <div class="w100 p15" *ngIf="!regimen.objectId && !consultationSession && !regimen.regimenPreparation && newInstructionUIExperiment">
        <cs-button class="tw-bg-teal-600 tw-rounded-full" [class]="'w100 primary'" [disabled]="isInternalUser" (callback)="triggerTreeForRegimen()">
          <cs-button-text class="tw-font-body" i18n="@@getYourTreatment">Get your treatment kit</cs-button-text>
        </cs-button>
      </div>
      <div class="w100 p15" *ngIf="!regimen.active && regimen.orderPlaced && !newInstructionUIExperiment">
        <cs-button [class]="'w100 secondary'" [disabled]="isInternalUser" (callback)="openRegimenOrderStatus()">
          <cs-button-text i18n="@@checkOrderDetails">Check Order Details</cs-button-text>
        </cs-button>
      </div>
      <div class="w100 p15" *ngIf="!regimen.active && regimen.orderPlaced && newInstructionUIExperiment">
        <cs-button class="tw-border-teal-600 tw-text-teal-600" [class]="'w100 secondary'" [disabled]="isInternalUser" (callback)="openRegimenOrderStatus()">
          <cs-button-text i18n="@@checkOrderDetails">Check Order Details</cs-button-text>
        </cs-button>
      </div>
<!--      Old instruction page cta container -->
      <div class="w100 p15 flex justify-around" *ngIf="regimen.active && regimen.showProductSummary && !newInstructionUIExperiment">
        <div class="w47">
          <cs-button class="tw-border tw-rounded-full tw-border-black tw-text-green-400" [class]="'w100 purple btn-xs'" (callback)="showPriceDetails()">
            <cs-button-text i18n="@@fullDetails">Full Details</cs-button-text>
          </cs-button>
        </div>
        <div class="w47">
          <cs-button class="tw-border tw-rounded-full tw-border-green-400" [class]="'w100 primary btn-xs'" [disabled]="isInternalUser" (callback)="reorder()">
            <cs-button-text i18n="@@reorder">Reorder</cs-button-text>
          </cs-button>
        </div>
      </div>
      <!--      New instruction page cta container -->
      <div class="tw-w-full tw-h-18 tw-p-4" *ngIf="regimen.active && regimen.showProductSummary && newInstructionUIExperiment">
        <button class="tw-bg-teal-600 tw-w-full tw-py-2 tw-px-6 tw-text-white tw-text-center tw-rounded-3 tw-font-body tw-font-bold tw-h-[40px]"
                (click)="showPriceDetails()"
                i18n="@@readDetailedInstructions"
        >Read Detailed Instructions</button>
      </div>
      <div class="w100 p15" *ngIf="regimen.active && regimen.showPriceDetail && !newInstructionUIExperiment">
        <cs-button [class]="'w100 secondary'" [disabled]="isInternalUser" (callback)="showSummaryView()">
          <cs-button-text i18n="@@readInstructions">Read Instructions</cs-button-text>
        </cs-button>
      </div>
      <div class="tw-w-full tw-h-18 tw-p-4" *ngIf="regimen.active && regimen.showPriceDetail && newInstructionUIExperiment">
        <button [disabled]="isInternalUser" class="tw-bg-teal-600 tw-w-full tw-py-2 tw-px-6 tw-text-white tw-text-center tw-rounded-3 tw-font-body tw-font-bold tw-h-[40px]"
                (click)="showSummaryView()" i18n="@@readInstructions">Read Instructions</button>
      </div>
      <div class="w100 p15 flex justify-around" *ngIf="!regimen.active && regimen.showProductSummary && !regimen.orderPlaced && !newInstructionUIExperiment">
        <div class="w47">
          <cs-button class="tw-border tw-rounded-full tw-border-black tw-text-green-400" [class]="'w100 purple btn-xs'" [disabled]="isInternalUser" (callback)="readMoreAboutProducts()">
            <cs-button-text i18n="@@fullDetails">Full Details</cs-button-text>
          </cs-button>
        </div>
        <div class="w47">
          <cs-button id="buyNowButton" [class]="isTabActive ? 'w100 primary shine btn-xs' : 'w100 primary btn-xs'" [disabled]="isInternalUser" (callback)="buyRegimen()">
            <cs-button-text i18n="@@buyNow">Buy Now</cs-button-text>
          </cs-button>
        </div>
      </div>

      <div class="w100 p15 flex justify-around" *ngIf="!regimen.active && regimen.showProductSummary && !regimen.orderPlaced && newInstructionUIExperiment">
        <div class="w47">
          <cs-button class="tw-border tw-rounded-full tw-border-teal-600 tw-text-teal-600" [class]="'w100 purple btn-xs'" [disabled]="isInternalUser" (callback)="readMoreAboutProducts()">
            <cs-button-text i18n="@@fullDetails">Full Details</cs-button-text>
          </cs-button>
        </div>
        <div class="w47">
          <cs-button id="buyNowButton" class="tw-bg-teal-600 tw-rounded-full" [class]="isTabActive ? 'w100 primary shine btn-xs' : 'w100 primary btn-xs'" [disabled]="isInternalUser" (callback)="buyRegimen()">
            <cs-button-text i18n="@@buyNow">Buy Now</cs-button-text>
          </cs-button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, ElementRef, EventEmitter, Input, Output, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { LocalStorageService } from '@services/local-storage-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { BroadcastService } from '@services/broadcast-service';
import { ChatProgressService } from '@services/chat-progress-service';
import { WindowRefService } from '@services/window-ref-service';
import { TimeService } from '@services/time-service';
import { CurrentComponentService } from '@services/current-component';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { ToolbarComponent } from '@components/toolbar/toolbar.component';
import { ToolbarTabsComponent } from '@components/toolbar-tabs/toolbar-tabs.component';
import { ApiClientConstant, ParseKeys } from 'api-client';
import { LanguageChangeComponent } from '@shared/bottom-sheet-layouts/language-change/language-change.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ConsultationSessionService } from '@services/consultation-session-service';
import { AppConfig } from '../../../app.config';
import { Regimen } from '../../../app-constant-types';

@Component({
  selector: 'user-view-regimen',
  templateUrl: './user-view-regimen.html',
  styleUrls: ['./user-view-regimen.scss'],
})
export class UserViewRegimenComponent implements OnDestroy {
  autoScrollToBottom: () => void = () => {
    if (this.regimenModuleContainer) {
      this.regimenModuleContainer.nativeElement.scroll(0, this.regimenModuleContainer.nativeElement.scrollHeight);
      this.regimenModuleContainer.nativeElement.scrollTop = this.regimenModuleContainer.nativeElement.scrollHeight;
    }
  };
  @ViewChild('toolbar', { static: false }) toolbar: ToolbarComponent;
  @ViewChild('toolbarTabs', { static: false }) toolbarTabs: ToolbarTabsComponent;
  @ViewChild('regimenModuleContainer', { static: false }) regimenModuleContainer: ElementRef;
  @Input('animation') animation: any;
  isTabActive: boolean = false;
  isFromHowToUse: boolean = false;
  @Input('refresh')
  set refresh(isActive: boolean) {
    this.isTabActive = isActive;
    if (isActive) {
      this.currentComponentService.set(this);
    } else {
      this.regimen = {};
      if (this.regimenModuleContainer) this.regimenModuleContainer.nativeElement.scrollTop = 0;
      this.currentComponentService.remove(this);
    }
  }
  @Output('showNewRegimen') showRegimen: EventEmitter<any> = new EventEmitter<any>();
  headerHeight: number = 0; // Toolbar + ToolbarTabs
  tabData: any = { count: 2 };
  isInternalUser: boolean;
  username: string;
  supportTicketId: string;
  user: any;
  consultationSession: any;
  regimenClasses: string[];
  regimen: Regimen = {};
  regimens: Array<any> = [];
  userRegimens: Array<any> = [];
  experiments: any[];
  showDescriptionExperiment: boolean = false;
  socialProof: any[] = [];
  loading: boolean = false;
  loadingByChild: boolean = false;
  subscriptions: Array<Subscription> = [];
  offerBanner: any = {};
  offerTimer: any = {};
  regimenOrder: any;
  redirectAfterRegimenPreparation: boolean = false;
  isCartUpdated: boolean = false;
  showReAcquisitionBanner: boolean = false;
  reAacquisitionCall: any;
  cartState: any = {
    totalPrice: 0,
    discountedPrice: 0,
  };
  cartDiscount: number;
  plan: string = '';
  allocatedRegimens: Array<any> = [];
  showSkinReport: boolean = false;
  chatProgressExperiment: boolean = false;
  isChatLoading: boolean = false;
  chatProgressValue: number;
  chatProgressType: string;
  isRegimenNewPropositionUI: boolean = false;
  newRegimenPricingExperiment: any;
  newInstructionUIExperiment: boolean = false;
  public lastScrollTop: number = 0;
  scrollingDown: boolean = false;
  chatLanguageChangeEnabled: boolean = false;

  constructor(private conn: ConnectionService,
    private router: Router,
    private route: ActivatedRoute,
    public appConfig: AppConfig,
    private eventLoggerService: EventLoggerService,
    private localStorageService: LocalStorageService,
    private broadcast: BroadcastService,
    private window: WindowRefService,
    private timeService: TimeService,
    private currentComponentService: CurrentComponentService,
    private appBridge: AppWebBridgeService,
    private zone: NgZone,
    private commonUtil: CommonUtilityHelper,
    private readonly appWebBridge: AppWebBridgeService,
    private readonly bottomSheet: MatBottomSheet,
    public chatProgressService: ChatProgressService,
    private readonly consultationSessionService: ConsultationSessionService,
  ) {
    this.currentComponentService.set(this);
    chatProgressService.getSelectedChatType().subscribe((value: string): void => {
      this.chatProgressType = value;
    });
    chatProgressService.getChatProgressState().subscribe((value: number): void => {
      this.chatProgressValue = value;
    });
  }

  async ngOnInit(): Promise<any> {
    this.isFromHowToUse = this.route.snapshot.queryParams?.fromHowToUse;
    if (this.route.snapshot.queryParams.supportTicketId) {
      this.supportTicketId = this.route.snapshot.queryParams.supportTicketId;
    }
    this.regimenClasses = [
      this.appConfig.Shared.Regimen.Class.FACE,
      this.appConfig.Shared.Regimen.Class.HAIR,
      this.appConfig.Shared.Regimen.Class.BODY,
    ];
    this.user = await this.conn.getActingUser();
    if (!this.user.get('allocatedDoctor')) this.conn.getActingUser().fetch();
    this.isInternalUser = this.conn.isInternalUser();
    this.regimens = this.regimenClasses.map((regimenClass: string): Object => ({ class: regimenClass }));
    await this.fetchExperiments();
    this.enableChatLanguageChange();
    this.experiments.forEach((exp: any): void => {
      if (exp.key === 'chat_progress_bar' && this.appBridge?.requestOSInformation() !== 'iOS') {
        this.chatProgressExperiment = true;
      }
      if (exp.key === 'add_extra_regimen_products') {
        this.newRegimenPricingExperiment = exp;
      }
      if (exp.key === 'instruction_page_redesign') {
        this.newInstructionUIExperiment = true;
      }
    });
    this.activateSubscription();
    await this.checkForReAcquisitionCall();
  }

  async checkForReAcquisitionCall(): Promise<void> {
    if ((this.user?.get('totalAmountSpent') || 0) < this.appConfig.Shared.User.MinimumAmountSpentFromReAcq) {
      return;
    }
    this.allocatedRegimens = await this.conn.fetchRegimens();
    if (this.allocatedRegimens?.length) {
      return;
    }
    const callPending = await this.conn.getCountOfUserPendingCall({
      where: {
        user: this.user,
        status: this.appConfig.Shared.PendingCall.Status.CallCompleted,
        createdAt: { $gte: this.timeService.removeMonths(new Date(), 4) },
      },
    });
    this.reAacquisitionCall = await this.conn.findUserReacquisitionCall();
    if (!this.user?.get('marketingTags')?.includes(this.appConfig.Shared.MarketingTags.Type.ON_REGIMEN)
      && this.user?.get('orderState') === this.appConfig.Shared.User.OrderState.DELIVERED
      && !callPending
      && !this.reAacquisitionCall?.length) {
      this.showReAcquisitionBanner = true;
    }
  }

  async fetchExperiments(): Promise<void> {
    this.experiments = await this.conn.findUserActiveExperiments(this.route.snapshot.queryParams.username);
  }

  activateSubscription(): void {
    this.subscriptions.push(this.route.queryParams.subscribe(async (params: any): Promise<any> => {
      if (params.resetSelection) {
        this.regimen = this.regimens.find((each: any): boolean => (each.class !== params.class));
      }
      if (params.tab !== 'regimen' || (this.regimen && params.class && params.class === this.regimen.class)) return;
      this.localStorageService.setValue('regimenUpdateVisited', true);
      const regimenClass = params?.class === this.appConfig.Shared.Regimen.Class.BODY
        ? this.appConfig.Shared.Regimen.Class.FACE : params?.class;
      await this.fetchRegimens(regimenClass, params.regimenId);
      if (params.instructionPage) this.showInstructionPage();
    }));
  }

  private async fetchRegimens(defaultActiveClass?: any, regimenId?: any): Promise<any> {
    if (!this.user) return;
    this.loading = true;
    this.conn.allowRegimenForceFetch = true;
    const include: ParseKeys<Table.Regimen>[] = [
      'morning.product' as 'morning',
      'night.product' as 'night',
      'regimenTag',
      'variants.products' as 'variants',
      'variants.morning.product' as 'variants',
      'variants.night.product' as 'variants',
    ];
    this.userRegimens = await this.conn.fetchRegimens(regimenId, true, false, false, include);
    const isBodyRegimen = (regimen: any): boolean => regimen.class === this.appConfig.Shared.Regimen.Class.BODY && regimen.active;
    const foundBodyRegimen = this.userRegimens.find(isBodyRegimen);
    const hasBodyRegimen = !!foundBodyRegimen;
    this.tabData = { count: hasBodyRegimen ? 3 : 2 };
    this.loading = false;
    this.loadingByChild = false;
    this.processRegimenDetails();
    await this.chooseActiveRegimenClass(defaultActiveClass);
  }

  setIsRegimenNewPropositionUI(): void {
    try {
      this.experiments.forEach((exp: any): void => {
        if (exp.key === 'regimen_new_proposition_ui') {
          this.isRegimenNewPropositionUI = this.regimen.class === this.appConfig.Shared.Regimen.Class.FACE;
        }
      });
    } catch (err) {
      this.isRegimenNewPropositionUI = false;
    }
  }

  /** Chooses which class of regimen to be shown
   * 1. shows unbought regimen (i.e not orderPlaced & not active & not expired)
   * 2. else , shows recent active regimen tab
   * 3. else , shows 1st available regimen tab
   * 4. else , shows user's mainconcern class tab  or default class (FACE)
   * @param defaultActiveClass - default class to be selected.
   */
  private async chooseActiveRegimenClass(defaultActiveClass?: string): Promise<any> {
    let activeClass;
    let defaultReg;
    if (this.isFromHowToUse) {
      defaultReg = this.userRegimens.find((each: any): any => each.active);
    }
    if (!defaultReg) defaultReg = this.userRegimens.find((each: any): any => !each.active && !each.expired && !each.orderPlaced);
    if (!defaultReg) defaultReg = this.userRegimens.find((each: any): any => each.active);
    if (defaultReg && defaultReg.class) activeClass = defaultReg.class;
    else {
      activeClass = this.userRegimens?.length
        ? this.userRegimens[0].class
        : (this.user?.get('PrivateMainConcernClass') || this.appConfig.Shared.Regimen.Class.FACE);
    }
    this.selectRegimenOfClass(defaultActiveClass || activeClass);
  }

  /** Chooses the regimen of selected class.
   * Updates the flag of regimen based of state of regimen it is, which helps to show the specific component.
   * Routes to same regimen tab with class in queryParam in order to save it in browser history. which helps to show that specific
   * class again when visited back.
   * @param regimenClass - class of regimen to be selected.
   */
  private async selectRegimenOfClass(regimenClass: string): Promise<any> {
    if (!this.isTabActive) return;
    this.consultationSession = undefined;
    if (this.regimenModuleContainer) {
      this.regimenModuleContainer.nativeElement.scrollTop = 0;
    }
    this.regimen = this.regimens.find((regimen: any): boolean => (regimen.class === regimenClass));
    this.setIsRegimenNewPropositionUI();

    this.tabData.selectedTab = this.regimenClasses.findIndex((each: any): boolean => each === this.regimen.class);
    await this.updateRegimenDisplayFlags(this.regimen);

    if (this.isInternalUser) return;
    setTimeout((): void => {
      this.router.navigate(
        ['/user'],
        {
          queryParams: { tab: 'regimen',
            class: this.regimen.class,
            supportTicketId: this.route.snapshot.queryParams.supportTicketId,
            username: this.route.snapshot.queryParams.username },
          replaceUrl: true,
        });
    }, 0);
    await this.fetchRegimenOrder();
  }

  /**
   * Process regimen's total mrp, sp, discount and normalizes few more information for easy access in child components.
   * And updates the normalized regimens in 'this.regimen' from 'this.userRegimens'. 'this.regimens' will be used across after this process.
   */
  private processRegimenDetails(): void {
    this.userRegimens.forEach((regimen_: any): void => {
      const regimen = regimen_;
      this.updateUserRegimenInRegimenList(regimen);
      if (!regimen.objectId) return;
      regimen.totalMRP = Math.round(regimen.fixedPriceMRP);
      regimen.totalSP = regimen?.fixedPrice;
      regimen.discount = Math.floor(((regimen.totalMRP - regimen.totalSP) * 100) / regimen.totalMRP);

      const { morning, night }: any = regimen;
      [].concat(morning).concat(night).forEach((item_: any): void => {
        const item = item_;
        if (item.instruction) {
          item.instruction = item.instruction.split('.').filter((ins: any): void => ins.trim().length);
        } else {
          item.instruction = [item.product.purposeDescription, 'Purchase regimen to see your instructions.'];
          if (this.showDescriptionExperiment) item.instruction = [item.product.description, 'Purchase regimen to see your instructions.'];
        }
      });
    });
  }

  private updateUserRegimenInRegimenList(userRegimen: any): void {
    this.regimens = this.regimens.map((emptyRegimen: any): void => {
      // Replaces processed regimen in the regimens template.
      if (emptyRegimen.class === userRegimen.class) return userRegimen;
      return emptyRegimen;
    });
  }

  /**
   * Sets the flag in regimen based on regimen state
   * 1. regimenPreparation - if regimen is in preparation time.
   * 2. showProductSummary - if queryParam 'instructions' is set or once regimen become active
   * 3. showConsultationView - if regimen has not morning, night products set in it.
   * 4. showPriceDetail - default, if regimen is unbought.
   * @param regimen_ - regimen in which flags are to be set.
   */
  async updateRegimenDisplayFlags(regimen_: any): Promise<void> {
    const regimen = regimen_;
    if (!regimen.objectId) {
      const session = await this.conn.findConsultationSession(this.regimen?.class);
      if (session?.get('PrivateMainConcern')) {
        this.consultationSession = session;
        await this.conn.switchUserToState(
          `v4_${this.consultationSession?.get('PrivateMainConcernClass').toLowerCase()}:`,
          { consultationSessionId: this.consultationSession.id });
        return;
      }
      return;
    }

    this.resetRegimenDisplayFlags(regimen);

    const hasInstructionSet = regimen.morning.every((each: any): boolean => each.instructionSet !== undefined)
      && regimen.night.every((each: any): boolean => each.instructionSet !== undefined);

    if (this.commonUtil.isRegimenBeingPrepared(regimen)) {
      this.processChecksForRegimenPreparation();
      regimen.regimenPreparation = true;
    }

    if (this.route.snapshot.queryParams.instructions) {
      regimen.showProductSummary = true;
      return;
    }
    if (regimen.regimenPreparation) return;
    if (![...regimen.morning, ...regimen.night].length) regimen.showConsultationView = true;
    else if (regimen.showSummaryView && regimen.active && hasInstructionSet) regimen.showProductSummary = true;
    else regimen.showPriceDetail = true;
  }

  processChecksForRegimenPreparation(): void {
    this.checkAndSetSkinReportVisibility();
  }

  checkAndSetSkinReportVisibility(): void {
    const femaleGender = this.user?.get('Gender') === this.appConfig.Shared.Gender.FEMALE;
    const faceRegimen = this.regimen.class === this.appConfig.Shared.Regimen.Class.FACE;
    if (femaleGender && faceRegimen) {
      this.showSkinReport = true;
    }
  }

  private resetRegimenDisplayFlags(regimen_: any): void {
    const regimen = regimen_;
    regimen.showConsultationView = false;
    regimen.showProductSummary = false;
    regimen.showPriceDetail = false;
    regimen.regimenPreparation = false;
    if (this.regimenModuleContainer) {
      this.regimenModuleContainer.nativeElement.scrollTop = 0;
    }
  }

  private showInstructionPage(): void {
    this.resetRegimenDisplayFlags(this.regimen);
    this.regimen.showProductSummary = true;
  }

  resetFlagAndShowRegimen(): void {
    this.redirectAfterRegimenPreparation = true;
    this.showRegimen.emit(true);
    this.updateRegimenDisplayFlags(this.regimen);
  }

  async readMoreAboutProducts(): Promise<void> {
    this.eventLoggerService.trackEvent('REGIMEN_VIEW_READ_ABOUT_PRODUCTS', { username: this.user?.get('username') });
    await this.router.navigate(['/user/regimen/products'],
      { queryParams: { class: this.regimen.class, username: this.user?.get('username'), regimenId: this.regimen.regimenId } });
  }

  async showPriceDetails(): Promise<void> {
    this.eventLoggerService.trackInParse('KNOW_MORE', ApiClientConstant.Event.Type.ORDER);
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'instruction-full-details' }));
    this.resetRegimenDisplayFlags(this.regimen);
    if (this.regimen.active) {
      await this.readMoreAboutProducts();
      return;
    }
    this.regimen.showPriceDetail = true;
    if (this.regimenModuleContainer) {
      this.regimenModuleContainer.nativeElement.scrollTop = 0;
    }
  }

  showSummaryView(): void {
    this.resetRegimenDisplayFlags(this.regimen);
    this.regimen.showProductSummary = true;
    if (this.regimenModuleContainer) {
      this.regimenModuleContainer.nativeElement.scrollTop = 0;
    }
  }

  async buyRegimen(): Promise<void> {
    this.eventLoggerService.cleverTapEvent('buyNowRegimen', JSON.stringify({ regimenId: this.regimen.regimenId }));
    this.eventLoggerService.trackInParse('BUY_NOW', ApiClientConstant.Event.Type.ORDER);
    this.eventLoggerService.trackEvent('BUY_NOW_CLICKED');
    this.eventLoggerService.trackInFirebaseAndBranch('BUY_NOW_CLICKED');
    if (this.regimen.class === this.appConfig.Shared.Regimen.Class.HAIR) {
      this.eventLoggerService.trackEvent('BUY_NOW_CLICKED_HAIR');
      this.eventLoggerService.trackInFirebaseAndBranch('BUY_NOW_CLICKED_HAIR');
    }
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'instructions-buy-now-regimen' }));
    if (this.newRegimenPricingExperiment) {
      const extraProductIds = this.newRegimenPricingExperiment?.variant?.availableProducts || [];
      await this.conn.updateRegimenPrice({ regimenId: this.regimen.regimenId, extraProductIds });
    }
    this.router.navigate(['/user/checkout'], { queryParams: { type: 'REGIMEN', regimenId: this.regimen.regimenId } });
    this.appWebBridge.logEventInBranchAndFirebaseFromiOS({
      branch: { name: 'BUY_NOW_CLICKED' },
      firebase: { name: 'BUY_NOW_CLICKED' },
    });
  }

  async reorder(): Promise<any> {
    if (this.isInternalUser) return;
    const morningProducts = this.regimen.morning.map((morning: any): string => morning.product.objectId);
    const nightProducts = this.regimen.night.map((morning: any): string => morning.product.objectId);
    const ids = Array.from(new Set([...morningProducts, ...nightProducts]));
    this.conn.navigateToURL(`/cart?products=${ids.join(',')}&type=REGIMEN`);
  }

  /**
   * Triggers tree for the regimen and routes to chat tab to start answering the BOT questions. if the consultation session exists.
   * Else route to main concern page of specific class to start the consultation.
   */
  async triggerTreeForRegimen(): Promise<void> {
    const session = await this.conn.findConsultationSession(this.regimen?.class);
    if (session && session?.get('PrivateMainConcern')) {
      try {
        const concerns = (session?.get('concernList') || []).length ? session?.get('concernList') : [session?.get('PrivateMainConcern')];
        await this.conn.saveUserMainConcern(concerns);
        await this.router.navigate(['/user'],
          { queryParams: { tab: 'chat', username: this.user?.get('username') } });
      } catch (err) {
        this.broadcast.broadcast('NOTIFY', { message: err.toString() });
      }
    } else {
      await this.router.navigate(['/mainConcern'], { queryParams: { class: this.regimen.class, redirectOnBack: '/home' } });
    }
  }

  async fetchRegimenOrder(): Promise<any> {
    delete this.regimenOrder;
    if (!this.regimen.objectId || !this.regimen.orderPlaced) return;
    const payload: any = { user: this.user, regimenId: this.regimen.regimenId, type: this.appConfig.Shared.Order.Type.REGIMEN };
    this.regimenOrder = (await this.conn.fetchOrders(payload, ['objectId'])).shift();
  }

  async openRegimenOrderStatus(): Promise<any> {
    if (this.regimenOrder) this.router.navigate([`/user/order/${this.regimenOrder.id}`]);
    else this.router.navigate(['/user/orders']);
  }

  swipeCallback(event: any): void {
    if (!this.regimen.class) return;
    let curIndex = this.regimenClasses.findIndex((each: any): boolean => each === this.regimen.class);
    if (curIndex < 0) curIndex = 0;
    if (event === 'LEFT' && curIndex < this.regimenClasses.length - 1) {
      curIndex += 1;
    } else if (event === 'RIGHT' && curIndex > 0) {
      curIndex -= 1;
    }
    this.selectRegimenOfClass(this.regimenClasses[curIndex]);
  }

  tabChange(index: any): void {
    if (!this.regimen.class) return;
    this.selectRegimenOfClass(this.regimenClasses[index]);
  }

  /**
   * If back pressed from regimen summary view, it shows the price detail view and cancels the browser back navigation.
   * Else it tells the browser to handle it by returning false (i.e means not handled locally)
   */
  async handleBackPress(): Promise<any> {
    if (this.regimen && this.regimen.showProductSummary && !this.regimen.active) {
      await this.showPriceDetails();
      return true;
    }
    this.router.navigate(['/user'], { queryParams: { tab: 'home', init: 'true' }, replaceUrl: true });
    return false;
  }

  back(): void {
    const supportTicketId = this.route.snapshot.queryParams;

    if (this.regimen?.showProductSummary && !supportTicketId) {
      this.handleBackPress();
      return;
    }

    if (supportTicketId) {
      this.router.navigate(['/support/ticket'], { replaceUrl: true });
    }

    this.router.navigate(['/user'], { queryParams: { tab: 'home', init: 'true' }, replaceUrl: true });
  }

  updatePrice(cartObject: any): void {
    // list of selected products -> needed for updateCart API
    // selectedProducts original price and selling price
    const cartState: any = cartObject?.cartState;
    // This property is used to differentiate b/w targetted regimens
    // Cart updation should happen only for face regimen
    // and inside face regimen, we should update the cart only for user interaction, not for the backend flow.
    // Because then price mismatch will happen
    const isCartUpdated: any = cartObject?.isCartUpdated;
    this.cartState = { ...cartState };
    const totalCartPrice = this.regimen.totalMRP + cartState.totalPrice;
    const totalCartDiscount = this.regimen.totalSP + cartState.discountedPrice;
    this.cartDiscount = Math.floor(((totalCartPrice - totalCartDiscount) / totalCartPrice) * 100);
    this.isCartUpdated = isCartUpdated;
  }

  async updateRegimen(regimen_: any): Promise<void> {
    // update the price
    const regimen = regimen_;
    regimen.totalMRP = Math.round(regimen.fixedPriceMRP);
    regimen.totalSP = regimen?.fixedPrice;
    regimen.discount = Math.floor(((regimen.totalMRP - regimen.totalSP) * 100) / regimen.totalMRP);

    if (this.regimenModuleContainer) {
      this.regimenModuleContainer.nativeElement.scrollTop = 0;
    }
  }

  /** Removes component from currentComponentService, so that no other component handles this components back press. */
  ngOnDestroy(): void {
    this.isTabActive = false;
    this.showRegimen.emit(false);
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
    this.currentComponentService.remove(this);
    if (this.regimenModuleContainer) {
      this.regimenModuleContainer.nativeElement.removeEventListener('scroll', this.onScroll.bind(this));
    }
  }

  parentLoading(loading: boolean): void {
    this.loadingByChild = loading;
  }

  selectedPlan(plan: string): void {
    this.plan = plan;
  }

  async handleClick(): Promise<void> {
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 're-acq-followup-banner-yes-regimen' }));
    const oldReAcquisitionResponse = this.user?.get('reAcquisitionResponse');
    if (!oldReAcquisitionResponse?.totalReAcquisitionResponse) oldReAcquisitionResponse.totalReAcquisitionResponse = 0;
    const updatedReAcquisitionResponse = {
      totalReAcquisitionResponse: oldReAcquisitionResponse.totalReAcquisitionResponse += 1,
      reAcquisitionResponseYes: true,
      lastReAcquisititionResponseTime: new Date().toISOString(),
    };
    this.user.set('reAcquisitionResponse', updatedReAcquisitionResponse);
    await this.user.save();
    this.router.navigate(['re-acquisition/user-query']);
  }

  get discountSpinnerFillColor(): string {
    switch (this.plan) {
      case 'regular': return '#76539A';
      case 'advanced': return '#D1903E';
      case 'pro': return '#4973B4';
      default: return '#D66736';
    }
  }

  enableChatLanguageChange(): void {
    try {
      this.experiments.forEach((exp: any): void => {
        if (exp.key === 'chat_language_change') {
          this.chatLanguageChangeEnabled = true;
        }
      });
    } catch (err) {
      this.chatLanguageChangeEnabled = false;
    }
  }

  openLanguageChangeDrawer(): void {
    const botChats = this.consultationSessionService.getChats().filter((chat: any): boolean => chat.get('Owner') === 'BOT');
    const latestBotChat = botChats.length > 0 ? botChats[botChats.length - 1] : undefined;
    this.bottomSheet.open(LanguageChangeComponent, {
      data: {
        translateLastBotQuestionData: {
          questionId: latestBotChat?.get('questionId'),
          regimenClass: this.consultationSession?.get('PrivateMainConcernClass'),
          user: this.user,
        },
      },
    });
  }

  handleLoadingStatus(isLoading: boolean): void {
    this.isChatLoading = isLoading;
    console.log(this.isChatLoading);
  }

  ngAfterViewInit(): void {
    if (this.regimenModuleContainer) {
      this.regimenModuleContainer.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    }
  }

  public onScroll(): void {
    const SCROLL_THRESHOLD = 3;
    const { scrollTop }: { scrollTop: number } = this.regimenModuleContainer.nativeElement;

    // Check if the scroll difference exceeds the threshold
    if (Math.abs(scrollTop - this.lastScrollTop) > SCROLL_THRESHOLD) {
      this.scrollingDown = scrollTop > this.lastScrollTop;
    }

    this.lastScrollTop = scrollTop;
  }
}
